// since the payloads from /token and /embedded are slighly different, I had to create a type
// that unifies them both (I didnt simply create a union so we dont have a cyclic dependency and extracting the types
// felt excessive) <@arieh>
export type AuthPayload = {
    userId: string;
    companyId: string;
    token: string;
    admin?: boolean;
    callbackUrl?: string;
    auditeeCompanyId?: string;
    type?: string;
};

export const BOOKKEEPING_PAGES = ['/overview', '/tax-hub'];

export const isBookkeepingRoute = (data: AuthPayload) => {
    const { callbackUrl } = data;

    //since callbackUrl might also include query parameters we need to parse it to get the pathname
    const { pathname } = new URL(callbackUrl as string, window.location.toString());

    return !!(data.auditeeCompanyId && BOOKKEEPING_PAGES.includes(pathname));
};
